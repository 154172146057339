import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons'
import { RoundIconSyle } from '../styles'

interface IProps {
  address: string
}

export const MapPinIconLink = ({address}: IProps) => {
  return <>
    <RoundIcon 
      href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
        address
      )}`}
      target='_blank'
      rel='noopener noreferrer'
    >
      <FontAwesomeIcon icon={faMapMarkerAlt} color='#61788A'/>
    </RoundIcon>
  </>
}


const RoundIcon = styled.a`
  ${RoundIconSyle};

  padding: 6px 11px;
`