import React, { ButtonHTMLAttributes } from 'react'
import styled, {css} from 'styled-components'
import LoadingIcon from '../LoadingIcon'

interface IButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  isLoading?: boolean
}

function Button({isLoading, ...rest}: IButtonProps) {
  return (
    <StyledButton disabled={!!isLoading} {...rest}>
      {!isLoading && rest.children}
      {isLoading && (<LoadingIcon/>)}
    </StyledButton>
  )
}

export const buttonStyle = css`
display: flex;
align-items: center;
justify-content: center;

box-sizing: border-box;
height: 40px;
width: 100%;
padding: 4px 12px;

background-color: #0887ef;
border-radius: 4px;
border: none;
outline: none;

color: white;
font-weight: 700;
font-size: 18px;
font-family: "Plus Jakarta Sans", sans-serif;
text-decoration: none;

cursor: pointer;

transition: background-color 250ms ease;

&[disabled] {
  opacity: 0.7;
}

&:hover {
  background-color: #0a5c9e;
}`

const StyledButton = styled.button`
  ${buttonStyle}
`

export default Button
