import React from 'react'
import styled from 'styled-components'
import { media } from '../../../util/breakpoints'

function PossibilitiesBlock() {
  return (
    <Features>
      <Container>
        <Title>Um link, várias possibilidades</Title>
        <Text>De aluguéis de temporada a hotéis e pousadas, o Hostli oferece soluções práticas para todos os tipos de hospedagem.</Text>
        <BlockContainer>
          <Block>
            <BlockImg alt="Páginas do hostli.com.br" src="/images/smartphone-images/hostli-5.png"/>
            <BlockLink>Aluguéis de temporada</BlockLink>
          </Block>
          <Block>
            <BlockImg alt="Páginas do hostli.com.br" src="/images/smartphone-images/hostli-8.png"/>
            <BlockLink>Hotéis</BlockLink>
          </Block>
          <Block>
            <BlockImg alt="Páginas do hostli.com.br" src="/images/smartphone-images/hostli-7.png"/>
            <BlockLink>Pousadas</BlockLink>
          </Block>
          <Block>
            <BlockImg alt="Páginas do hostli.com.br" src="/images/smartphone-images/hostli-6.png"/>
            <BlockLink>Hostels</BlockLink>
          </Block>
          {/* <Block>
            <BlockImg alt="Páginas do hostli.com.br" src="/images/smartphone-images/10.png"/>
            <BlockLink>Outras Propriedades</BlockLink>
          </Block> */}
        </BlockContainer>
      </Container>
    </Features>
  )
}

const Features = styled.div`
  background-color: #EF5D60;
  display: flex;
  justify-content: center;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  max-width: 1200px;
  padding: 70px;

  text-align: left;

  ${media.upToTabM} {
    min-height: 65vh;
    padding: 70px 5%;
  }
`

const Block = styled.a`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  max-width: 190px;

  cursor: pointer;

  text-align: center;

  transition: .15s transform ease-in;

  ${media.upToTabM} {
    max-width: 100%;
  }


  &:hover {
    transform: translateY(-4px);
  }
`

const BlockContainer = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;

  ${media.upToTabM} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 5px;
  }
`

const BlockImg = styled.img`
  max-width: 100%;
  max-height: 400px;
  padding: 8px;
`

const BlockLink = styled.span`
  width: 100%;
  display: block;
  
  color: white;
  font-size: 18px;
  font-weight: 700;
  text-decoration: none;
  text-align: center;

  &:visited {
    color: white;
  }
`

const Title = styled.h2`
  margin: 0;
  width: 100%;

  color: #fff;
  font-size: 52px;
  font-weight: 600;
  text-align: left;
  line-height: 52px;

  ${media.tabMUp} {
    font-size: 48px;
  }
`

const Text = styled.p`
  color: #fff;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
`

export default PossibilitiesBlock
