import React from 'react'

import CTABlock from './CTABlock'
import FeaturesBlock from './FeaturesBlock'
import FooterCTABlock from './FooterCTABlock'
import HowToBlock from './HowToBlock'
import PossibilitiesBlock from './PossibilitiesBlock'

function Home() {
  return (
    <div>
      <CTABlock/>
      <FeaturesBlock/>
      <HowToBlock/>
      <PossibilitiesBlock/>
      <FooterCTABlock/>
    </div>
  )
}

export default Home
