import React from 'react'
import styled from 'styled-components'
import { IContact } from '../../../shared/places/interfaces';
import { media } from '../../../util/breakpoints';

interface ContactProps {
  contact?: IContact
}

function Contact (props: ContactProps) {
  if (!props.contact) {
    return <></>
  }

  const { name, phone, whatsapp, profileImage } = props.contact

  return <>
    <ContactContainer>
      <CotentWrapper>
        {profileImage && <ProfileImage src={profileImage} alt={name} />}
        <ContactInfo>
            <ContactName>{name}</ContactName>
            <ContactLinks>
              <ContactLink href={`tel:${phone}`} target="_blank">Telefone</ContactLink>
              <ContactLink href={`https://wa.me/${whatsapp}`} target="_blank">WhatsApp</ContactLink>
            </ContactLinks>
        </ContactInfo>
      </CotentWrapper>
    </ContactContainer>
  </>
}

const CotentWrapper = styled.div`
  max-width: 920px;
  width: 100%;

  display: flex;
  align-items: center;
  padding: 32px 16px;

  ${media.deskGUp} {
    width: 920px;
  }
`

const ContactContainer = styled.div`
  background-color: #f5f5f5;

  display: flex;
  justify-content: center;
`;

const ProfileImage = styled.img`
  width: 64px;
  height: 64px;
  border-radius: 50%;
  margin-right: 16px;
  object-fit: cover;
`;

const ContactInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

const ContactName = styled.h2`
  margin: 0 0 8px;
  font-size: 1.5em;
`;

const ContactLink = styled.a`
  display: inline-block;
  text-align: center;
  margin: 4px 4px 4px 0;
  padding: 6px 14px;
  border-radius: 32px;

  color: #61788A;
  text-decoration: none;
  font-size: 0.9em;
  font-weight: 500;

  background-color: #D1F5FF;
  

  transition: background-color ease 0.3s;

  &:hover {
    background-color: #abedff;
  }
`;

const ContactLinks = styled.div``

export default Contact