import { observable, action, computed } from 'mobx'

import { IRecommendation } from '../shared/places/interfaces'
import RecommendationService from '../services/RecommendationService'


class RecommendationStore {
  @observable
  recommendations: IRecommendation[] = []

  setRecommendations(recommendations: IRecommendation[]) {
    this.recommendations = recommendations
  }

  @computed
  get totalRecommendation(): number {
    return this.recommendations.length
  }

  @computed
  get orderedRecommendation(): IRecommendation[] {
    return this.recommendations.slice().sort(
      (a, b) =>
         a.order_view - b.order_view
    )
  }

  @action
  async createRecommendation(recommendation: IRecommendation) {
    const response = await RecommendationService.createRecommendation(recommendation)

    this.recommendations.push(response.data)
  }

  @action
  async updateRecommendation(id: number, recommendation: IRecommendation) {
    const response = await RecommendationService.updateRecommendation(id, recommendation)

    const updatedRecommendationIndex = this.recommendations.findIndex(recommendation => recommendation.id === id)

    this.recommendations[updatedRecommendationIndex] = response.data
  }

  @action
  async deleteRecommendation(id: number) {
    await RecommendationService.deleteRecommendation(id)

    this.recommendations = this.recommendations.filter(recommendation => recommendation.id !== id)
  }

  @action
  async reorder(startIndex: number, endIndex: number) {
    const result = Array.from(this.recommendations)
    const [removed] = result.splice(startIndex, 1)

    result.splice(endIndex, 0, removed)

    this.recommendations = result.map((recommendation, i) => {
      recommendation.order_view = i
      return recommendation
    })

    const positionList = this.recommendations.reduce((prev, next) => {
      prev[next.id!.toString()] = next.order_view
      return prev
    }, {} as {[index: string]: number})

    return RecommendationService.updateRecommendationOrder(positionList)
  }

  async upload(file: string, extension: string, name: string): Promise<string> {
    const fileName = `${name}.${extension}`

    const storageService = await import('../services/FirebaseStorageService')

    await storageService.default.uploadBase64(fileName, file)
    return await storageService.default.getItemUrl(fileName)
  }
}

const storeInstance = new RecommendationStore()
export default storeInstance
