import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'

const ACCESS_TOKEN_LOCAL_STORAGE_KEY = 't'

export class BaseService {
  // protected baseUrl = 'https://gramlink.co/api'
  protected baseUrl = 'https://api.hostli.com.br/api'

  private getClient(): AxiosInstance {
    return axios.create({
      baseURL: this.baseUrl,
      headers: this.getAuthHeader()
    })
  }

  getAuthHeader() {
    return {
      authorization: this.getAccessToken()
    }
  }

  getAccessToken(): string {
    return localStorage.getItem(ACCESS_TOKEN_LOCAL_STORAGE_KEY) || ''
  }

  setAccessToken(value: string) {
    localStorage.setItem(ACCESS_TOKEN_LOCAL_STORAGE_KEY, value || '')
  }

  async get<U>(path: string, config: AxiosRequestConfig = {}): Promise<U> {
    return this.getClient().get(path, config)
  }

  async post<U>(path: string, params: any, config: AxiosRequestConfig = {}): Promise<U> {
    return this.getClient().post(path, params, config)
  }

  async put<U>(path: string, params: any, config: AxiosRequestConfig = {}): Promise<U> {
    return this.getClient().put(path, params, config)
  }

  async delete<U>(path: string, config: AxiosRequestConfig = {}): Promise<U> {
    return this.getClient().delete(path, config)
  }
}
