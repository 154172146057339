
import { IRecommendation } from '../shared/places/interfaces'
import { BaseService } from './BaseService'

interface ICreateRecommendationRequest {
  data: IRecommendation
}

interface IUpdateRecommendationOrderRequest {
  data: number[]
}

interface IUpdateRecommendationOrderDTO {
  [id: string]: number
}

class RecommendationService extends BaseService {

  async createRecommendation(data: IRecommendation): Promise<ICreateRecommendationRequest> {
    return this.post('/recommendation', data)
  }

  async updateRecommendation(id: number, data: IRecommendation): Promise<ICreateRecommendationRequest> {
    return this.put(`/recommendation/${id}`, data)
  }

  async deleteRecommendation(id: number): Promise<IUpdateRecommendationOrderRequest> {
    return this.delete(`/recommendation/${id}`)
  }

  async updateRecommendationOrder(data: IUpdateRecommendationOrderDTO): Promise<IUpdateRecommendationOrderRequest> {
    return this.post(`/recommendation/update-order`, data)
  }
}

export default new RecommendationService()
