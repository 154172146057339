
import { ISection } from '../shared/places/interfaces'
import { BaseService } from './BaseService'

interface ICreateSectionRequest {
  data: ISection
}

interface IUpdateSectionOrderRequest {
  data: number[]
}

interface IUpdateSectionOrderDTO {
  [id: string]: number
}

class SectionService extends BaseService {

  async createSection(data: ISection): Promise<ICreateSectionRequest> {
    return this.post('/section', data)
  }

  async updateSection(id: number, data: ISection): Promise<ICreateSectionRequest> {
    return this.put(`/section/${id}`, data)
  }

  async deleteSection(id: number): Promise<IUpdateSectionOrderRequest> {
    return this.delete(`/section/${id}`)
  }

  async updateSectionOrder(data: IUpdateSectionOrderDTO): Promise<IUpdateSectionOrderRequest> {
    return this.post(`/section/update-order`, data)
  }
}

export default new SectionService()
