import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { media } from '../../../util/breakpoints'
import { sendCreateAccountEvent } from '../../../shared/google-analytics'

function HowToBlock() {
  return (
    <Container>
      <Title>Rápido e simples</Title>
      <BlockContainer>
        <Block>
          <BlockTitle><span>1.</span> crie sua conta</BlockTitle>
          <BlockDescription>Crie sua conta e adicione os detalhes da sua propriedade</BlockDescription>
        </Block>
        <Block>
          <BlockTitle><span>2.</span> adicione conteúdo</BlockTitle>
          <BlockDescription>Personalize as informações de check-in, Wi-Fi, Regras da Casa e mais</BlockDescription>
        </Block>
        <Block>
          <BlockTitle><span>3.</span> compartilhe</BlockTitle>
          <BlockDescription>Encante seus hóspedes com uma página com todas as informações essenciais</BlockDescription>
        </Block>
      </BlockContainer>
      <CTALink onClick={() => sendCreateAccountEvent('how-to banner')} to="/criar-conta">Criar minha conta</CTALink>
    </Container>
  )
}


const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
  max-width: 1200px;
  margin: 0 auto;
  padding: 70px;

  text-align: left;

  ${media.upToTabM} {
    min-height: 65vh;
    padding: 70px 5%;
  }
`

const Block = styled.a`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  max-width: 270px;

  cursor: pointer;

  ${media.upToTabM} {
    max-width: 100%;
  }
`

const BlockContainer = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;


  ${media.upToTabM} {
    flex-direction: column;
  }
`

const BlockTitle = styled.p`
  font-size: 42px;
  font-weight: 600;
  margin: 24px 0;

  color: #212121;

  span {
    color: #EF5D60;
  }

  ${media.upToTabM} {
    margin: 24px 0;
  }
  
`

const BlockDescription = styled.p`
  font-size: 18px;

  color: #6D6D6D;

  ${media.upToTabM} {
    font-size: 26px;
    margin: 0 0 24px;
  }
`

const Title = styled.h2`
  margin: 0;
  width: 100%;
  padding-bottom: 40px;

  color: #212121;
  font-size: 52px;
  font-weight: 600;
  text-align: left;
  line-height: 52px;

  ${media.tabMUp} {
    font-size: 48px;
  }
`

const CTALink = styled(Link)`
  padding: 8px 24px;
  margin: 40px 0px 8px;
  background: #EF5D60;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.15),
              4px 4px 5px rgba(0, 0, 0, 0.15);

  border-radius: 4px;
  
  font-weight: bold;
  font-size: 24px;
  text-decoration: none;
  color: #FFFFFF;

`


export default HowToBlock
