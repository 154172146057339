import { observable, action, computed } from 'mobx'
import { ISection } from '../shared/places/interfaces'
import SectionService from '../services/SectionService.ts'


class SectionStore {
  @observable
  sections: ISection[] = []

  setSections(sections: ISection[]) {
    this.sections = sections
  }

  @computed
  get totalSection(): number {
    return this.sections.length
  }

  @computed
  get orderedSection(): ISection[] {
    return this.sections.slice().sort(
      (a, b) =>
         a.order_view - b.order_view
    )
  }

  @action
  async createSection(section: ISection) {
    const response = await SectionService.createSection(section)

    this.sections.push(response.data)
  }

  @action
  async updateSection(id: number, section: ISection) {
    const response = await SectionService.updateSection(id, section)

    const updatedSectionIndex = this.sections.findIndex(section => section.id === id)

    this.sections[updatedSectionIndex] = response.data
  }

  @action
  async deleteSection(id: number) {
    await SectionService.deleteSection(id)

    this.sections = this.sections.filter(section => section.id !== id)
  }

  @action
  async reorder(startIndex: number, endIndex: number) {
    const result = Array.from(this.sections)
    const [removed] = result.splice(startIndex, 1)

    result.splice(endIndex, 0, removed)

    this.sections = result.map((section, i) => {
      section.order_view = i
      return section
    })

    const positionList = this.sections.reduce((prev, next) => {
      prev[next.id!.toString()] = next.order_view
      return prev
    }, {} as {[index: string]: number})

    return SectionService.updateSectionOrder(positionList)
  }
}

const storeInstance = new SectionStore()
export default storeInstance
