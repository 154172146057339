import { BaseService } from './BaseService'

interface IRequest {
  data: any
}

class PlaceService extends BaseService {
  async getPlace(accountSlug: string): Promise<IRequest> {
    return this.get(`/account/${accountSlug}`)
  }
}

export default new PlaceService()
