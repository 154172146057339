import React from 'react'
import styled from 'styled-components'
import { media } from '../../../util/breakpoints'

interface HeroProps {
  placeDetails: {
    image?: string
    title: string
    description: string
  }
}

function Hero (props: HeroProps) {
  const {placeDetails} = props
  return (
    <>
      <HeroWrapper image={placeDetails.image}>
        <CotentWrapper>
          <div>
            <Title>Bem Vindo</Title>
            <SubTitle>{placeDetails.title}</SubTitle>
          </div>
          <p><b>{placeDetails.description}</b></p>
        </CotentWrapper>
      </HeroWrapper>
    </>
  )
}

interface IHeroWrapperProps {
  image?: string
}

const CotentWrapper = styled.div`
  max-width: 920px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`

const HeroWrapper = styled.div<IHeroWrapperProps>`
  width: 100%;
  min-height: 450px;
  padding: 50px;
  
  display: flex;
  align-items: center;
  justify-content: center;

  background: linear-gradient(
    0deg, 
    rgba(0, 0, 0, 0.3), 
    rgba(0, 0, 0, 0.3)), 
    url(${props => props.image || ''}
  );

  background-position: center center;
  background-size: cover;

  color: #ffffff;

  ${media.upToTabM} {
    height: 240px;
  }
`

const Title = styled.h1`
  margin-bottom: 12px;
  font-weight: bold;
  font-size: 62px;
`

const SubTitle = styled.p`
  font-size: 1.8em;
`

export default Hero