import React from 'react'
import styled from 'styled-components'
import { ISection } from '../../../shared/places/interfaces'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { media } from '../../../util/breakpoints'

interface SectionsProps {
  sections: ISection[]
}

function Sections (props: SectionsProps) {
  return <SectionWrapper>
      {props.sections.map((section, index) => (
        <SectionContainer key={index}>
          <CotentWrapper>
            <SectionTitle><span>{section.title}</span><FontAwesomeIcon icon={faChevronDown} color='#61788A'/></SectionTitle>
            <SectionContent dangerouslySetInnerHTML={{ __html: section.content }} />
          </CotentWrapper>
        </SectionContainer>
      ))}
  </SectionWrapper>
}

const accordionAnimation = (e: React.MouseEvent) =>
  e.currentTarget.parentElement?.parentElement?.classList.toggle('active')


const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0;
`

const CotentWrapper = styled.div`
  max-width: 920px;
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 16px;

  ${media.deskGUp} {
    width: 920px;
  }
`

const SectionContainer = styled.div`
  display: flex;
  justify-content: center;

  border-bottom: 1px solid #f5f5f5;
  padding: 16px;
  margin: 0;

  > div > div {
    display: none;
  }

  > div >  h2 > svg {
    rotate: 0;

    transition: rotate ease-in-out .2s;
  }

  &.active {
    div {
      display: block;
    }

    > div > h2 > svg {
      rotate: 180deg;
    }
  }
`

const SectionTitle = styled.h2.attrs({
  onClick: accordionAnimation
})`

  display: flex; 
  align-items: center;
  justify-content: space-between;

  margin: 0;
  padding: 32px 0;

  cursor: pointer;
`;

const SectionContent = styled.div`
  margin-top: 24px;
`;

export default Sections