import React from 'react'
import styled from 'styled-components'
import { IRecommendation } from '../../../shared/places/interfaces'
import { MapPinIconLink } from '../../../shared/MapPinIconLink'
import { media } from '../../../util/breakpoints'

interface RecommendationsProps {
  recommendations: IRecommendation[]
}

function Recommendations (props: RecommendationsProps) {
  if (!props.recommendations.length) {
    return <></>
  }

  return <>
    <Title>Recomendações</Title>
    {props.recommendations.map((recommendation, index) => (
      <RecommendationContent key={index}>
        <CotentWrapper>
          <RecommendationTitle>{recommendation.title}</RecommendationTitle>
          {recommendation.description && <RecommendationText>{recommendation.description}</RecommendationText>}
          {recommendation.address && (
            <AddressContainer>
              <RecommendationText><b>Endereço:</b> {recommendation.address}</RecommendationText>
              <MapPinIconLink address={recommendation.address}/>
            </AddressContainer>
          )}
          <LinksContainer>
            {recommendation.site && (
              <p><Link href={recommendation.site} target="_blank" rel="noopener noreferrer">Site</Link></p>
            )}
            {recommendation.phone && (
              <p><Link href={`tel:${recommendation.phone}`} target="_blank">Telefone</Link></p>
            )}
            {recommendation.whatsapp && (
              <p><Link href={`https://wa.me/${recommendation.whatsapp}`} target="_blank">WhatsApp</Link></p>
            )}
          </LinksContainer>
        </CotentWrapper>
      </RecommendationContent>
    ))}
  </>
}

const CotentWrapper = styled.div`
  max-width: 920px;
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 16px;

  ${media.deskGUp} {
    width: 920px;
  }
`

const Title = styled.h2`
  max-width: 920px;
  width: 100%;
  margin: 0 auto;
  padding: 48px 32px 8px;

  font-size: 1.8em;

  ${media.deskGUp} {
    width: 920px;
  }

`;

const RecommendationContent = styled.div`
  display: flex;
  justify-content: center;

  border-bottom: 1px solid #f5f5f5;
  padding: 16px;
  margin: 16px 0;
`;

const RecommendationTitle = styled.h2`
  margin-top: 0;
`;

const RecommendationText = styled.p`
  margin-top: 0;
`;

const LinksContainer = styled.div`
  display: flex;
`

const AddressContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

const Link = styled.a`
  display: inline-block;
  text-align: center;
  margin: 4px 8px 4px 0;
  padding: 6px 14px;
  border-radius: 32px;

  color: #61788A;
  text-decoration: none;
  font-size: 0.9em;
  font-weight: 500;

  background-color: #D1F5FF;

  transition: background-color ease 0.3s;

  &:hover {
    background-color: #abedff;
  }
`;



export default Recommendations