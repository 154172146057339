import React from 'react'
import styled from 'styled-components'

function NotFound(props: {term: string}) {
  return (
    <Container>
      <h1>Num achei esse tal de "{props.term}" não.</h1>
    </Container>
  )
}


const Container = styled.div`
  height: calc(100vh - 105px);
  display: flex;
  align-items: center;
  justify-content: center;
`

export default NotFound