import { IGeneralItem } from '../shared/places/interfaces'
import { BaseService } from './BaseService'

interface IUpsertSettingRequest {
  data: IGeneralItem
}

class AccountSettingService extends BaseService {
  async upsertSetting(key: string, data: Object): Promise<IUpsertSettingRequest> {
    return this.post(`/setting/${key}`, {value: data})
  }

  async deleteSetting(key: string): Promise<any> {
    return this.delete(`/setting/${key}`)
  }

}

export default new AccountSettingService()
