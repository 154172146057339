import React from 'react'
import styled from 'styled-components'

import { IAddress, ICheckInAndOut, IGeneral, IWifi } from '../../../shared/places/interfaces';
import { CopyIcon } from '../../../shared/CopyIcon';
import { MapPinIconLink } from '../../../shared/MapPinIconLink';
import { media } from '../../../util/breakpoints';
import { formatTimeRange } from '../../../util/checkin-time';
import { formatAddress } from '../../../util/format-address';

interface GeneralInfoProps {
  generalInfo: IGeneral
}

function GeneralInfo (props: GeneralInfoProps) {
  const {
    wifi,
    address,
    checkin,
    checkout
  } = props.generalInfo

  if (!wifi && !address && !checkin && !checkout) {
    return <></>
  }

  const wifiInfo = (wifi?: IWifi) => {
    if (wifi) {
      return <>
        <WifiContainer>
          <Subtitle>Internet Wi-Fi</Subtitle>
          <WifiCredentialContainer>
            <WifiCredential><b>Rede:</b> <span>{wifi.network} <CopyIcon text={wifi.network}/></span></WifiCredential>
            <WifiCredential><b>Senha:</b> <span>•••••••••••• <CopyIcon text={wifi.pass}/></span></WifiCredential>
          </WifiCredentialContainer>
        </WifiContainer>
      </>
    }
  }

  const addressInfo = (address?: IAddress) => {
    if (address) {
      return <>
        <SimpleContainer>
          <Subtitle>Endereço</Subtitle>
          <AddressText><span>{formatAddress(address)}</span><MapPinIconLink address={formatAddress(address)}/></AddressText>
        </SimpleContainer>
      </>
    }
  }

  const checkinInfo = (checkin?: ICheckInAndOut) => {
    if (checkin) {
      return <>
        <SimpleContainer>
          <Subtitle>Check-in</Subtitle>
          <CheckinText>{formatTimeRange(checkin.start, checkin.end)}</CheckinText>
          {checkin.details ? <CheckinDetail>{checkin.details}</CheckinDetail> : <></>}
        </SimpleContainer>
      </>
    }
  }

  const checkoutInfo = (checkout?: ICheckInAndOut) => {
    if (checkout) {
      return <>
        <SimpleContainer>
          <Subtitle>Checkout</Subtitle>
          <CheckinText>{formatTimeRange(checkout.start, checkout.end)}</CheckinText>
          {checkout.details ? <CheckinDetail>{checkout.details}</CheckinDetail> : <></>}
        </SimpleContainer>
      </>
    }
  }

  return <>
    <GeneralContainer>
      <CotentWrapper>
        <Title>Informações Gerais</Title>
        {wifiInfo(wifi)}
        {addressInfo(address)}
        {checkinInfo(checkin)}
        {checkoutInfo(checkout)}
      </CotentWrapper>
    </GeneralContainer>
  </>
}

const CotentWrapper = styled.div`
  max-width: 920px;
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 32px 16px;

  ${media.deskGUp} {
    width: 920px;
  }
`

const GeneralContainer = styled.div`
  display: flex;
  justify-content: center;

  border-bottom: 1px solid #f5f5f5;
  padding: 32px 16px;
  margin: 0;
`;

const Title = styled.h2`
  margin: 0 0 24px;
  font-size: 1.8em;
`;

const Subtitle = styled.h3`
  margin: 0 0 16px;
  font-size: 1.25em;
`;

const WifiContainer = styled.div`
  margin-bottom: 16px;
`;

const WifiCredentialContainer = styled.div`
`

const WifiCredential = styled.p`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  margin: 12px 0;

  font-size: 1em;
`;

const SimpleContainer = styled.div``;

const AddressText = styled.p`
  display: flex;
  align-items: center;
  justify-content: space-between;

  font-size: 1em;
  font-weight: 500;
  color: #61788A;
`;

const CheckinText = styled(AddressText)`
  margin: 8px 0;

  :last-child {
    margin-bottom: 16px;
  }

  :first-child {
    margin-top: 16px;
  }
`


const CheckinDetail = styled(CheckinText)`
  font-size: 0.95em;
  color: #8b949b;
`

export default GeneralInfo