import React from 'react'
import styled from 'styled-components'
import { media } from '../../util/breakpoints'


function accordionAnimation(e: React.MouseEvent) {
  const el = e.currentTarget
  const titleElement = el.querySelector('p:first-of-type')
  if (titleElement) {
    console.log(titleElement.classList)
    el.classList.toggle("active")
  }
}

function FAQ() {

  return (
    <>
      <HeaderWrapper>
        <Title>Dúvidas?</Title>
      </HeaderWrapper>
      <BodyWrapper>
        <SubTitle>
          Sobre o Hostli
        </SubTitle>
        <div>
          <QuestionWrapper>
            <Question>O que é o Hostli?</Question>
            <Answer>
              Hostli é uma plataforma de gestão de informações para proprietários de acomodações de aluguel de temporada e pequenos hotéis ou pousadas. Com o Hostli, você centraliza e organiza todas as informações essenciais para seus hóspedes, como instruções de check-in e check-out, detalhes de Wi-Fi, regras da casa, recomendações locais e orientações de segurança. A plataforma facilita o acesso dos hóspedes a essas informações antes e durante a estadia, melhorando a comunicação e a experiência, enquanto economiza tempo e reduz dúvidas frequentes dos proprietários e gestores.
            </Answer>
          </QuestionWrapper>
          <QuestionWrapper>
            <Question>Como eu crio uma conta?</Question>
            <Answer>
              Para criar uma conta basta você entrar na página <a href="/criar-conta">Criar Conta</a> e se registrar com o nome da sua propriedade, email válido e senha. É super simples!
            </Answer>
          </QuestionWrapper>
          <QuestionWrapper>
            <Question>O meio de pagamento da assinatura é seguro?</Question>
            <Answer>
              Muito! Nós utilizamos o Stripe para gerenciar os pagamentos. Todos pagamentos são feitos dentro do sistema do Stripe e nós não guardamos nenhuma informação sobre seu cartão de crédito. Saiba mais sobre o Stripe e seus termos de uso em <a href="https://stripe.com/br">https://stripe.com/</a>.
            </Answer>
          </QuestionWrapper>
          <QuestionWrapper>
            <Question>Como excluo minha conta?</Question>
            <Answer>
              Para excluir sua conta, é necessário nos enviar um email, a partir do email utilizado no cadastro. Você pode fazer isso clicando <a href="mailto:contato@hostli.com.br?subject=Quero%20excluir%20minha%20conta">aqui</a>.
            </Answer>
          </QuestionWrapper>
          <QuestionWrapper>
            <Question>Como cancelo o Premium?</Question>
            <Answer>
              Você pode cancelar a assinatura Premium pelo nosso painel. Esses são os passos:
              <ul>
                <li>Entrar no painel com seu email e senha</li>
                <li>No fim da página, na sessão "conta", clique em "Gerenciar assinatura"</li>
                <li>No painel do Stripe, selecione o plano de assinatura</li>
                <li>Cancele a assinatura</li>
                <li><b>Bônus:</b> Que tal nos dizer o motivo do cancelamento? Você pode fazer isso clicando <a href="mailto:contato@hostli.com.br?subject=Feedback%20da%20assinatura%20Premium">aqui</a>.</li>
              </ul>
            </Answer>
          </QuestionWrapper>
          {/* <QuestionWrapper>
            <Question>Como altero minha URL (usuário)?</Question>
            <Answer>
              Para alterar sua URL (seu usuário), é necessário nos enviar um email, a partir do email utilizado no cadastro. Você pode fazer isso clicando <a href="mailto:contato@hostli.com.br?subject=Quero%20alterar%20minha%20URL">aqui</a>. É importante ressaltar que uma vês que o seu usuário é alterado, a URL antiga deixa de funcionar e fica disponível para outros usuários.
            </Answer>
          </QuestionWrapper> */}
        </div>
      </BodyWrapper>
    </>
  )
}

const HeaderWrapper = styled.div`
  width: 100%;
  height: 350px;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  background-color: #EF5D60;

  ${media.upToTabM} {
    height: 240px;
  }
`

const BodyWrapper = styled.div`
  max-width: 900px;
  padding: 24px;
  margin: 0 auto;
  color: #212121;
`

const Title = styled.h1`
  max-width: 900px;
  width: 100%;
  margin: 0 auto;
  padding: 24px;

  color: #fff;
  font-size: 42px;
`

const SubTitle = styled.h2`
  margin: 48px 0;
  font-size: 34px;
`

const QuestionWrapper = styled.div.attrs({
  onClick: accordionAnimation
})`
  margin: 20px 0;
  padding: 24px;

  font-size: 28px;

  border: 1px solid #e6e6e6;
  border-radius: 4px;
  box-shadow: 2px 2px 4px 0px rgb(0, 0, 0, 0.1);

  &:last-of-type {
    margin-bottom: 86px;
  }

  &.active {
    p {
      display: block;
    }
  }
`

const Question = styled.p`
  margin: 12px 0;
  font-size: 26px;
  font-weight: bold;
  cursor: pointer;
`

const Answer = styled.p`
  display: none;
  font-size: 20px;
`


export default FAQ
