import React, { useState } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import { media } from '../../../util/breakpoints'
import { sendCreateAccountEvent } from '../../../shared/google-analytics'

let delayTimer: number

const handleCreateAccountClick = (_: React.MouseEvent) => {
  sendCreateAccountEvent('home cta banner')
}

function CTABlock() {
  return (
    <CTAWrapper>
      <CTAContainer>
        <Title>Transforme a experiência dos seus hóspedes!</Title>
        <SubTitle>Todas as informações importantes em um só lugar</SubTitle>
        <InputWrapper>
          <CTAButton onClick={handleCreateAccountClick} to={`/criar-conta`}>Experimente grátis</CTAButton>
        </InputWrapper>
      </CTAContainer>
      <ImageContainer>
        <FirstImage alt="Página de exemplo do hostli.com.br" src="/images/smartphone-images/hostli-1.png"/>
        <SecondImage alt="Painel do hostli.com.br" src="/images/smartphone-images/hostli-4.png"/>
      </ImageContainer>
    </CTAWrapper>
  )
}

const CTAWrapper = styled.div`
  display: flex;
  align-items: center;
  max-width: 1200px;

  margin: 0 auto;
  padding: 85px 70px;

  text-align: left;

  ${media.upToTabM} {
    flex-direction: column;
    text-align: center;
    min-height: 65vh;
    padding: 5%;
  }
`

const CTAContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;

  ${media.upToTabM} {
    margin-top: 35px;
  }
`

const Image = styled.img`
  max-width: 100%;
  max-height: 400px;
  padding: 8px;

  position: relative;
`

const FirstImage = styled(Image)`
  bottom: 20px;
  left: 25px;
  z-index: 1;
`

const SecondImage = styled(Image)`
  top: 20px;
  right: 25px;
`

const Title = styled.h1`
  margin: 0;
  padding-bottom: 22px;
  font-size: 72px;
  line-height: 74px;

  ${media.upToMobXL} {
    padding: 26px 0 12px;
    font-size: 58px;
    line-height: 60px;
  }
`

const SubTitle = styled.h2`
  margin: 0;
  width: 80%;
  color: #676767;
  font-weight: 400;
  font-size: 32px;
  line-height: 38px;

  ${media.upToTabM} {
    margin: 26px auto 12px;
  }

  ${media.upToMobXL} {
    font-size: 24px;
    line-height: 26px;
  }
`

const InputWrapper = styled.div`
  display: flex;
  align-items: center;

  text-align: left;

  margin-top: 36px;

  label {
    color: #676767;
  }

  input {
    color: #676767;
    transition: color .15s ease-in;

    &:focus {
      color: #212121;
    }
  }

  ${media.upToDeskG} {
    flex-direction: column;
    width: 100%;
    > div {
      width: 100%;
    }
  }
`

const CTAButton = styled(Link)`
  padding: 6px 38px;
  min-height: 40px;
  margin-left: 8px;
  border-radius: 6px;
  background-color: #EF5D60;
  color: #fff;
  text-decoration: none;
  font-weight: 600;
  font-size: 22px;
  text-align: center;

  transition: box-shadow .4s ease-in-out;

  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.1),
              4px 4px 5px rgba(0, 0, 0, 0.1);

  &:hover {
    box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.15),
                4px 4px 5px rgba(0, 0, 0, 0.15);
  }

  ${media.mobLUp} {
    margin-top: 12px;
  }

  ${media.upToDeskG} {
    width: 100%;
    margin-left: 0;
  }
`

export default CTABlock
